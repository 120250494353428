import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Editor from "../components/editor/Editor";
import PublishView from "../components/editor/PublishView";
import UserPreview from "../components/editor/UserPreview";
import TutorialView from "../components/editor/TutorialView";
import axios from "axios";
import { GET_HISTORY_URL, PUBLISH_FORM_URL } from "../util/Constants";

interface FormItem {
  id: number;
  cis_title: string;
  json: JSON;
  date_created: string;
}

function EditPage({ user, form }: { user: string, form: JSON | null}) {
  const navigate = useNavigate();

  const [history, setHistory] = useState<FormItem[]>([]);

  useEffect(() => {
    // Fetch history from the backend
    const fetchHistory = async () => {
      try {
        const response = await axios.get<FormItem[]>(GET_HISTORY_URL);
        const sortedHistory = response.data.sort((a: FormItem, b: FormItem) => {
          return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
        });
        setHistory(sortedHistory);
      } catch (error) {
        console.error('Error fetching history:', error);
      }
    };
  
    fetchHistory();
  }, []);

  const onSelectHistoryItem = (json: JSON) => {
    setTextAreaContent(JSON.stringify(json));
  };

  const [textAreaContent, setTextAreaContent] = useState(() => {
    const savedContent = localStorage.getItem("textAreaContent");
    return savedContent ? savedContent : JSON.stringify(form);
  });
  const [preview, setPreview] = useState(textAreaContent);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isPublishMode, setIsPublishMode] = useState(false);
  const [isTutorialMode, setIsTutorialMode] = useState(false);

  useEffect(() => {
    setPreview(textAreaContent);
    localStorage.setItem("textAreaContent", textAreaContent);
  }, [textAreaContent]);

  if (user === undefined) {
    navigate('/login');
  }

  const resetPreview = () => {
    const defaultContent = JSON.stringify(form);
    setPreview(defaultContent);
    setTextAreaContent(defaultContent);
    localStorage.setItem("textAreaContent", defaultContent);
  };

  const viewPreview = () => setIsPreviewMode(true);
  const editContent = () => {
    setIsPreviewMode(false);
    setIsPublishMode(false);
    setIsTutorialMode(false);
  };
  const enterTutorialMode = () =>setIsTutorialMode(true);
  const enterPublishMode = () => setIsPublishMode(true);

  const [formName, setFormName] = useState('Untitled Form');

  const confirmPublish = async () => {
    try {
      console.log("Publishing form:", formName);
      const response = await axios.post(PUBLISH_FORM_URL, {
        cis_title: formName,
        json: JSON.parse(textAreaContent),
      });
      console.log("Form Published! Response:", response.data);
      editContent();
    } catch (error) {
      console.error("Error publishing form:", error);
    }
  };

  return (
    <div className="box-border flex relative flex-col shrink-0 p-11 mt-5">
      {isPublishMode ? (
        <PublishView
          textAreaContent={textAreaContent}
          confirmPublish={confirmPublish}
          editContent={editContent}
          formName={formName}
          setFormName={setFormName} 
        />
      ) : isPreviewMode ? (
        <UserPreview
          textAreaContent={textAreaContent}
          editContent={editContent}
        />
      ) : isTutorialMode ? (
          <TutorialView
            videoSrc="https://youtube.com/embed/X08Fq7aBn_A"
            textAreaContent={textAreaContent}
            editContent={editContent}
          />
      ) : (
        <Editor
          preview={preview}
          textAreaContent={textAreaContent}
          setTextAreaContent={setTextAreaContent}
          resetPreview={resetPreview}
          viewPreview={viewPreview}
          enterPublishMode={enterPublishMode}
          enterTutorialMode={enterTutorialMode}
          history={history}
          onSelectHistoryItem={onSelectHistoryItem}
        />
      )}
    </div>
  );
}

export default EditPage;
