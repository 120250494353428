import React, { useState } from "react";
import FormPage from "../../pages/FormPage";

interface UserPreviewProps {
  textAreaContent: string;
  editContent: () => void;
}

const UserPreview: React.FC<UserPreviewProps> = ({ textAreaContent, editContent }) => {
  const [mobilePreview, setMobilePreview] = useState(false);

  const toggleMobilePreview = () => {
    setMobilePreview(prevState => !prevState);
  };

  return (
    <>
      <div style={{ margin: '0 auto', width: '60%' }}>
        <div className="flex justify-between items-center">
          <header className="box-border h-auto text-3xl">
            <h1>User Preview</h1>
          </header>
          <div>
            <button
              onClick={editContent}
              className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-blue-500 text-white"
              style={{ marginRight: '8px' }} // Reduced right margin to bring buttons closer
            >
              Back to Editing
            </button>
            <button
              onClick={toggleMobilePreview}
              className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-green-500 text-white"
            >
              {mobilePreview ? "Exit Mobile Preview" : "Enter Mobile Preview"}
            </button>
          </div>
        </div>
        <p className="mt-5" style={{ width: '70%', textAlign: 'left' }}>
          This is a preview of how the form will appear to users.
        </p>
      </div>
      {mobilePreview ? (
        <div style={{ margin: '0 auto', width: '375px', height: '667px', overflow: 'auto', marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
          <FormPage preview={false} mobilePreview={mobilePreview} json={textAreaContent} />
        </div>
      ) : (
        <FormPage preview={false} mobilePreview={mobilePreview} json={textAreaContent} />
      )}
    </>
  );
};

export default UserPreview;
