import React from "react";
import DropdownVideo from "./DropdownVideo";

interface TutorialViewProps {
  videoSrc : string;
  textAreaContent: string;
  editContent: () => void;
}

const TutorialView: React.FC<TutorialViewProps> = ({ videoSrc}) => (
<>
<div style={{ margin: '0 auto', width: '60%' }}>
  <div className="flex justify-between items-center">
    <header className="box-border h-auto text-3xl">
      <h1>Tutorial</h1>
    </header>
  </div>
      <span>
        Visit{" "}
        <a href="https://surveyjs.io/service/mysurveys" target="_blank" rel="noreferrer noopener" className="text-sky-600">
          <strong>
            <u>SurveyJS</u>
          </strong>
        </a>{" "}
        and login to edit the basic fields &amp; structure of the form as in the
        tutorial below.
      </span>
  <div>
    <DropdownVideo videoSrc={videoSrc} />
  </div>
</div>
</>
);

export default TutorialView;
