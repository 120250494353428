import React from "react";
import FormPage from "../../pages/FormPage";

interface PublishViewProps {
  textAreaContent: string;
  confirmPublish: () => void;
  editContent: () => void;
  formName: string;
  setFormName: React.Dispatch<React.SetStateAction<string>>;
}

const PublishView: React.FC<PublishViewProps> = ({ textAreaContent, confirmPublish, editContent, formName, setFormName}) => {
  const handleConfirmPublish = () => {
    confirmPublish();
  };

  return (
    <>
    <div style={{ margin: '0 auto', width: '60%' }}>
      <div className="flex justify-between items-center">
        <header className="box-border h-auto text-3xl">
          <h1>Confirmation</h1>
        </header>
        <div>
          <button
            onClick={editContent}
            className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-blue-500 text-white mr-4"
          >
            Back to Editing
          </button>
          <button
            onClick={handleConfirmPublish}
            className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-black text-white"
          >
            Confirm Publish
          </button>
        </div>
      </div>
      <input
        type="text"
        placeholder="Untitled Form"
        value={formName}
        onChange={(e) => setFormName(e.target.value)}
        className="mt-5 mb-3 w-100%"
        style={{ display: 'block', margin: 'auto' }}
      />
      <p className="mt-5" style={{ width: '70%', textAlign: 'left' }}>
        By clicking <strong>Confirm Publish</strong>, you acknowledge that this form will now be available for all applicants to fill out.
        <strong> Are you sure you want to proceed?</strong>
      </p>
    </div>
    <FormPage preview={false} mobilePreview={false} json={textAreaContent} />
  </>
  );
};

export default PublishView;
