import React from "react";
import FormTextArea from "./FormTextArea";

interface EditSectionProps {
  textAreaContent: string;
  setTextAreaContent: React.Dispatch<React.SetStateAction<string>>;
}

const EditSection: React.FC<EditSectionProps> = ({
  textAreaContent,
  setTextAreaContent,
}) => (
  <section className="box-border flex relative flex-col grow shrink-0 self-stretch px-5 pb-5 mx-auto w-full max-w-[1200px] min-h-[100px]">
    <div className="box-border relative shrink-0 mt-5 h-auto">
      <span>
        Once completed, paste the corresponding JSON below to preview your
        changes:
      </span>
    </div>
    <FormTextArea
      textAreaContent={textAreaContent}
      setTextAreaContent={setTextAreaContent}
      name="json"
      className="box-border flex relative flex-col shrink-0 p-2.5 mt-5 rounded border border-solid border-stone-300"
      required={false}
    />
  </section>
);

export default EditSection;
