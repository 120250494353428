import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import * as components from './pages';
import './input.css';
import { json } from './assets/form';
import { AUTH_GET_USER, GET_FORM_JSON_URL } from './util/Constants';

const App: React.FC = () => {
  const [formJson, setFormJson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    const fetchFormJson = async () => {
      try {
        const response = await axios.get(GET_FORM_JSON_URL);
        if (response.data && response.data.json) {
          setFormJson(response.data.json);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setFormJson(json as any);
        }
      } catch (error) {
        console.error('Error fetching form JSON:', error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFormJson(json as any);
      }
      setLoading(false);
    };

    fetchFormJson();
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const url = AUTH_GET_USER;
        const { data } = await axios.get(url, { withCredentials: true });
        if (data && data.email) {
          setUser(data.email);
          sessionStorage.setItem("user", JSON.stringify(data.email));
        }
      } catch (err) {
        console.error(err);
      }
      setUserLoading(false);
    };

    getUser();
  }, []);

  if (loading || userLoading) {
    return <div>Loading...</div>;
  }

  const users = [
    "anthony@bringtheweb.org",
    "ben@bringtheweb.org",
    "colby@bringtheweb.org",
    "crm@bringtheweb.org"
  ]

  const isAuthenticated = user && users.includes(user);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<components.FormPage preview={false} mobilePreview={false} json={formJson} />} />
        <Route path="/confirmation" element={<components.ConfirmationPage />} />
        <Route path="/edit" element={
          isAuthenticated ? (
            <components.EditPage user={user} form={formJson} />
          ) : (
            <Navigate replace to="/unauthorized" />
          )
        } />
        <Route path="/login" element={<components.LoginPage />} />
        <Route path="/unauthorized" element={<components.UnauthorizedPage />} />
      </Routes>
    </Router>
  );

};

export default App;
