import React from 'react';
import cis_logo from '../assets/cis_logo.png';

const ConfirmationPage: React.FC = () => {
  return (
    <div>
      <img src={cis_logo} alt="CIS" style={{ display: 'block', margin: '0 auto' }} />
      <div className="container mx-auto px-8 shadow-lg pt-10 pb-10" style={{ width: '60%' }}>
        <h1>Your application has been submitted</h1>
        <p>Please wait to be contacted by a CIS professional.</p>
      </div>
    </div>
  );
};

export default ConfirmationPage;
