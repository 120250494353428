import React, { useState } from "react";
import VideoDiv from "./VideoDiv";

const DropdownVideo = ({ videoSrc }: { videoSrc: string }) => {
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => setShowVideo(!showVideo);

  return (
    <div className="inline-block relative">
      <button
        onClick={toggleVideo}
        className="bg-white text-black py-2 px-4 rounded inline-flex items-center focus:outline-none"
        type="button"
      >
        {/* Arrow icon which rotates when the video is shown */}
        <svg
          className={`w-4 h-4 mr-2 transform transition-transform ${showVideo ? 'rotate-180' : 'rotate-0'}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
        <span>Show Tutorial</span>
      </button>

      {showVideo && <VideoDiv videoSrc={videoSrc} />}
    </div>
  );
};

export default DropdownVideo;
