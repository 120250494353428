import React from "react";

const VideoDiv: React.FC<{ videoSrc: string }> = ({ videoSrc }) => (
  <div className="box-border flex relative flex-col shrink-0 mt-5 w-full pointer-events-auto min-h-[20px] min-w-[20px]">
    <div className="relative">
      <iframe
        width="660"
        height="415"
        src={videoSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </div>
);

export default VideoDiv;
