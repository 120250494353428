import React, { useState } from "react";
import FormPage from "../../pages/FormPage";
import EditSection from "./EditSection";
import { FORM_URL } from "../../util/Constants";

interface EditorProps {
  preview: string;
  textAreaContent: string;
  setTextAreaContent: React.Dispatch<React.SetStateAction<string>>;
  resetPreview: () => void;
  viewPreview: () => void;
  enterPublishMode: () => void;
  enterTutorialMode: () => void;
  history: { id: number; cis_title: string, json: JSON; date_created: string }[];
  onSelectHistoryItem: (json: JSON) => void;
}

interface AdditionalFieldsSectionProps {
  children: React.ReactNode;
}

const AdditionalFieldsSection: React.FC<AdditionalFieldsSectionProps> = ({
  children,
}) => (
  <section className="box-border relative grow self-stretch pb-5 mx-auto w-full h-auto max-w-[1200px] min-h-[100px]">
    {children}
  </section>
);

const Editor: React.FC<EditorProps> = ({
  preview,
  textAreaContent,
  setTextAreaContent,
  resetPreview,
  viewPreview,
  enterPublishMode,
  enterTutorialMode,
  history,
  onSelectHistoryItem,
}) => {
  const [isHistoryDropdownOpen, setIsHistoryDropdownOpen] = useState(false);
  const toggleHistoryDropdown = () => setIsHistoryDropdownOpen(!isHistoryDropdownOpen);

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() >= 12 ? 'PM' : 'AM';
    
    return `${hours}:${minutes} ${period}`;
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
  
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
      <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
        <header className="box-border relative shrink-0 h-auto text-3xl">
          <h1>Preview</h1>
        </header>
        <FormPage preview={true} mobilePreview={false} json={preview} />
      </div>
      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
        <div className="box-border flex relative flex-col shrink-0 mb-0 grow-0">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <header className="box-border h-auto text-3xl">
                Edit Form
              </header>
              <button
                onClick={toggleHistoryDropdown}
                className="ml-4 pt-2 pr-4 pb-1.5 pl-4 text-center cursor-pointer text-black underline hover:no-underline"
              >
                {isHistoryDropdownOpen ? 'Close History' : 'Open History'}
              </button>
            </div>
            <div>
              {/* Reset to Current Button */}
              <button
                className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-lightred-200 text-white mr-2"
                style={{ backgroundColor: '#f95053' }} // Using a light red color
                onClick={resetPreview}
              >
                Reset
              </button>
              {/* Back to Editing Button */}
              <button
                onClick={enterTutorialMode}
                className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-blue-500 text-white mr-2"
              >
                Tutorial
              </button>
              {/* Save to Preview Button */}
              <button
                className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-zinc-500 text-white mr-2"
                onClick={viewPreview}
              >
                User Preview
              </button>
              {/* Publish Button */}
              <button
                className="box-border pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-black text-white"
                onClick={enterPublishMode}
              >
                Publish
              </button>
              {/* To CRM */}
              <button
                onClick={() => window.open(FORM_URL)}
                className="box-border ml-2 pt-2 pr-4 pb-1.5 pl-4 w-auto h-auto text-center rounded appearance-none cursor-pointer bg-green-900 text-white"
              >
                To CRM
              </button>
            </div>
          </div>
          {isHistoryDropdownOpen && (
            <div className="history-dropdown">
              {history.map((entry) => (
                <div className="history-dropdown-item" key={entry.id} onClick={() => onSelectHistoryItem(entry.json)}>
                  <span className="history-title">{entry.cis_title}</span>
                  <span className="history-datetime">
                    <strong>{formatTime(entry.date_created)} - {formatDate(entry.date_created)}</strong>
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="box-border flex relative flex-col shrink-0 px-5 pb-5 min-h-[100px]">
          <EditSection
            textAreaContent={textAreaContent}
            setTextAreaContent={setTextAreaContent}
          />
        </div>
        <header className="box-border relative shrink-0 mt-5 h-auto text-2xl">
          <h1>Additional Fields</h1>
        </header>
        <div className="box-border relative px-5 pt-10 pb-5 h-auto min-h-[100px]">
          <AdditionalFieldsSection>
            <b>[Toggle Button]</b> Address with Autofill
          </AdditionalFieldsSection>
        </div>
      </div>
    </div>
  )

};

export default Editor;
