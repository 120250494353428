const BACKEND_URL: string = 'https://api.crm.bringtheweb.org/';
const FORM_URL: string = 'https://form.crm.bringtheweb.org/';
const CLIENTS_URL: string = BACKEND_URL + 'clients/';
const NEW_CLIENT_URL: string = CLIENTS_URL + 'new-form-client';
const GET_HISTORY_URL: string = BACKEND_URL + 'form/history';
const AUTH_GET_USER: string = BACKEND_URL + 'auth/getUser';
const GET_FORM_JSON_URL: string = BACKEND_URL + 'form/';
const PUBLISH_FORM_URL: string = BACKEND_URL + 'form/publish';
const GOOGLE_CALLBACK_URL: string = BACKEND_URL + 'auth/google/callback';

export {
	BACKEND_URL,
	CLIENTS_URL,
	NEW_CLIENT_URL,
	GET_HISTORY_URL,
	AUTH_GET_USER,
	GET_FORM_JSON_URL,
	FORM_URL,
	PUBLISH_FORM_URL,
	GOOGLE_CALLBACK_URL,
};
