import React, { useState, useEffect } from 'react';

interface FormTextAreaProps {
  textAreaContent: string; // Assuming this is a JSON string
  setTextAreaContent: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  className: string;
  required: boolean;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  textAreaContent,
  setTextAreaContent,
  name,
  className,
  required,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editableContent, setEditableContent] = useState('');
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const originalHeight = '400px';
  const expandedHeight = '800px';

  // Effect to format JSON content for editing on component mount or when textAreaContent changes
  useEffect(() => {
    try {
      // Format the JSON string to be pretty printed in the textarea
      const formattedContent = JSON.stringify(JSON.parse(textAreaContent), null, 2);
      setEditableContent(formattedContent);
    } catch (error) {
      // Handle the case where textAreaContent is not a valid JSON string
      console.error("Error formatting the JSON content:", error);
      setEditableContent(textAreaContent); // Fallback to raw content
    }
  }, [textAreaContent]);

  const toggleTextAreaSize = () => {
    if (!isExpanded) {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = expandedHeight;
      }
    } else {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = originalHeight;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
    setIsExpanded(!isExpanded);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableContent(e.target.value);
    try {
      // Update the original state with minified JSON when content is valid JSON
      const minifiedJSON = JSON.stringify(JSON.parse(e.target.value));
      setTextAreaContent(minifiedJSON);
    } catch (error) {
      // Optionally handle invalid JSON input here
      console.error("Error updating the content:", error);
    }
  };

  return (
    <>
      <textarea
        ref={textAreaRef}
        placeholder="Enter JSON content here"
        name={name}
        value={editableContent}
        onChange={handleChange}
        className={className}
        required={required}
        style={{ height: originalHeight }}
      />
      <button
        onClick={toggleTextAreaSize}
        className="mt-2 bg-gray-200 text-gray-700 py-1 px-3 rounded hover:bg-gray-300"
      >
        {isExpanded ? "Collapse Content" : "Expand Content"}
      </button>
    </>
  );
};

export default FormTextArea;
