export const json = {
  "title": "Every1Online Plus - Sign up Form",
  "description": "Sign up for our Every1Online Plus Internet service.",
  "logoPosition": "right",
  "pages": [
   {
    "name": "contact",
    "elements": [
     {
      "type": "text",
      "name": "first_name",
      "title": "First Name",
      "isRequired": true,
      "autocomplete": "given-name"
     },
     {
      "type": "text",
      "name": "last_name",
      "startWithNewLine": false,
      "title": "Last Name",
      "isRequired": true,
      "autocomplete": "family-name"
     },
     {
      "type": "text",
      "name": "email",
      "title": "Email",
      "isRequired": true,
      "validators": [
       {
        "type": "email",
        "text": "Invalid email address. Must be in the format abc@123.org"
       }
      ],
      "inputType": "email",
      "autocomplete": "email"
     },
     {
      "type": "text",
      "name": "phone",
      "startWithNewLine": false,
      "title": "Phone Number",
      "description": "(123) 456-7890",
      "isRequired": true,
      "inputType": "tel",
      "maskType": "pattern",
      "maskSettings": {
       "saveMaskedValue": true,
       "pattern": "(999) 999-9999"
      }
     }
    ],
    "title": "Contact"
   },
   {
    "name": "address",
    "elements": [
     {
      "type": "text",
      "name": "street",
      "title": "Street Address",
      "isRequired": true,
      "autocomplete": "street-address"
     },
     {
      "type": "text",
      "name": "question2",
      "title": "City",
      "isRequired": true,
      "autocomplete": "address-level2"
     },
     {
      "type": "text",
      "name": "state",
      "startWithNewLine": false,
      "title": "State",
      "description": "(e.g. PA)\n",
      "isRequired": true,
      "autocomplete": "address-level1",
      "maxLength": 2
     },
     {
      "type": "text",
      "name": "postal-code",
      "startWithNewLine": false,
      "title": "Postal Code",
      "isRequired": true,
      "autocomplete": "postal-code"
     },
     {
      "type": "text",
      "name": "question5",
      "title": "Apartment/Building Number"
     }
    ],
    "title": "Address"
   },
   {
    "name": "page1",
    "elements": [
     {
      "type": "radiogroup",
      "name": "current-internet",
      "title": "Do you currently have in-home internet?",
      "choices": [
       {
        "value": "Item 1",
        "text": "Yes"
       },
       {
        "value": "Item 2",
        "text": "No"
       },
       {
        "value": "Item 3",
        "text": "Yes, but from my cell phone or mobile device."
       }
      ]
     },
     {
      "type": "radiogroup",
      "name": "child",
      "title": "Does a school age child use your internet at home?",
      "choices": [
       {
        "value": "Item 1",
        "text": "Yes"
       },
       {
        "value": "Item 2",
        "text": "No"
       }
      ]
     },
     {
      "type": "radiogroup",
      "name": "elderly",
      "title": "Does someone over 65 years old use the Internet at your home?",
      "choices": [
       {
        "value": "Item 1",
        "text": "Yes"
       },
       {
        "value": "Item 2",
        "text": "No"
       }
      ],
      "otherText": "Other:"
     },
     {
      "type": "radiogroup",
      "name": "interest",
      "title": "I am interested in Every1Online because...",
      "isRequired": true,
      "choices": [
       {
        "value": "Item 1",
        "text": "The cost of the Internet is too high."
       },
       {
        "value": "Item 2",
        "text": "The Internet is unavailable at my home."
       },
       {
        "value": "Item 3",
        "text": "I am worried about privacy."
       }
      ],
      "showOtherItem": true,
      "otherText": "Other:"
     }
    ],
    "title": "Additional Information"
   }
  ],
  "showQuestionNumbers": "onPage",
  "questionsOnPageMode": "singlePage"
 }