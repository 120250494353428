import React from 'react';
import cis_logo from '../assets/cis_logo.png';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { themeJson } from "../assets/theme";
import axios from "axios";
import { NEW_CLIENT_URL } from '../util/Constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormPage: React.FC<{ preview: boolean, mobilePreview: boolean, json: any }> = ({ preview, mobilePreview, json }) => {
  const survey = new Model(json);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  survey.applyTheme(themeJson as any);

  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
    console.log(options);
    const clientData = {
      first_name: sender.data["first_name"] || '',
      last_name: sender.data["last_name"] || '',
      name: `${sender.data["first_name"]} ${sender.data["last_name"]}` ,
      email: [sender.data["email"]],
      phones: [sender.data["phone"]],
      address: `${sender.data["street"]} ${sender.data["question2"]} ${sender.data["state"]} ${sender.data["postal-code"]}`,
      street1: sender.data["street"],
      city: sender.data["question2"],
      state: sender.data["state"],
      zip_code: sender.data["postal-code"]
    };
    console.log(JSON.stringify(clientData));

    axios.post(NEW_CLIENT_URL, clientData)
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
      console.error(err);
    });
  });

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logoSrc = preview ? "../../assets/cis_logo.png" : cis_logo;

  return (
    preview ?
      <div style={{ outline: '2px solid #3351a8' }}>
        <Survey model={survey} />
      </div> :
      <div className="container mx-auto px-8 mt-20 mb-20 shadow-lg"
        style={{
          width: (width < 800 || mobilePreview) ? '100%' : '60%',
          margin: width < 800 ? '0' : '',
          padding: width < 800 ? '0' : ''
        }}>
        <img src={logoSrc} alt="CIS" style={{ display: 'block', margin: '0 auto' }} />
        <Survey model={survey} />
      </div>
  );

};

export default FormPage;
